
import {
    Component,
  Watch,
} from 'vue-property-decorator';
import accountModule from '@/store/modules/accountModule';
import assetsModule from '@/store/modules/assetsModule';
import scheduleModule from '@/store/modules/scheduleModule';
import { mixins } from 'vue-class-component';
import Multiselect from 'vue-multiselect';
import { getNameByEmail } from '@/utils/users';
import { v4 as uuidv4 } from 'uuid';
import {
  getComponent,
    addNumberPadding,
    sleep,
    getElementPosition,
    getElementCenterPosition,
  getConfigEnv,
} from '@/utils/helpers';
import {
    SCHEDULE_CHART_DAY_WIDTH,
    SCHEDULE_ROW_HEIGHT,
    SCHEDULE_PIXELS_TO_CURRENT_DAY,
  SCHEDULE_ROW_STACKED_HEIGHT,
  SCHEDULE_START_DATE_OFFSET_HOURS,
} from '@/lib/constants';

import workspaceModule from '@/store/modules/workspaceModule';
import workflowModule from '@/store/modules/workflowModule';

@Component({
    components: {
        AppLayout: () => getComponent('common/AppLayout'),
        RigSchedule: () => getComponent('schedule/RigSchedule'),
    ComponentDetails: () => getComponent('schedule/ComponentDetails'),
    AddJob: () => getComponent('schedule/AddJob'),
    AddField: () => getComponent('schedule/AddField'),
    AddShift: () => getComponent('schedule/AddShift'),
    EditShift: () => getComponent('schedule/EditShift'),
    AddShiftTemplate: () => getComponent('schedule/AddShiftTemplate'),
    NewShiftWrapper: () => getComponent('schedule/NewShiftWrapper'),
    EditScheduleGroup: () => getComponent('schedule/EditScheduleGroup'),
    EditShiftTemplate: () => getComponent('schedule/EditShiftTemplate'),
    Dropdown: () => getComponent('common/Dropdown'),
    Multiselect,
    },
})

export default class PersonnelSchedulerGantt extends mixins() {
  reloadGanttChartKey = 0

  reloadGanttChartComponentsKey = 0

  totalAssetsDictionaryKey = 0

  chartStartDate = 0

  dropdownTypeShowing = ''

  dropdownLocation: any[] = []

  assetFilterOptions: any[] = [{ text: 'Test', value: 'Test' }]

  assetFiltersSelected: any[] = []

  assetFiltersConfirmed: any[] = []

  userFilterOptions: any[] = [{ text: 'Test', value: 'Test' }]

  userFiltersSelected: any[] = []

  userFiltersConfirmed: any[] = []

  isDeletingTemplate = false

  isHoveringOverTemplateID = ''

  isDeletingTemplateByID = ''

    shiftTemplates: any[] = [];

  showSaveSuccessfulNotification = false

  showSavingDetailsBannerID = false

  savingDetailsBannerIDText = ''

  showChangeButtons = false

  // Loading views
    dataLoading = false

  loadingGanttChart = false

  dataLoadingDeleteRow = false

  savingChartComponentChange = false

  isUpdatingScheduleView = false

  // Gantt chart data
    viewTotalDays = 17

  dates: any[] = []

    currentDate = ''

    rows: any[] = []

  confirmDialogShowing: any = null;

  confirmDialogTitle = ''

  confirmDialogMessage = ''

  popupBackground = false

  addNewJobPopupInnerShowing = false

  addShiftTemplatePopupShowing = false

  addIconPosX = -100

  addIconPosY = -100

  cursorPosComponent = null

  chartStartDatetime

  chartEndDateTime

  isResizingElement = false

  didDragElement = false

  existingTableID = ''

  addNewWorkspacePopupAddField = false

  selectionElement;

  selectionElementStartPoint;

  isSelectingChartArea = false

  totalSelectedComponents: any = {}

  areasExpanded = []

  routesExpanded = []

  padsExpanded = []

  totalAssets: any = []

  assetSpeedDictVals: any = {}

  didHoverOverTitleCellID = null

  didSelectTitleCellID = null

  showAddShiftPopupVal = false

  isLoadingRightArrow = false

  isLoadingLeftArrow = false

  minChartStartDate;

  maxChartEndDate;

  persistentIDs = {}

  initialTotalAssetsDictionary:any = null

  totalAssetsDictionary:any = null

  reverseAssetsDictionary:any = {}

  get shouldShowToggleIcon() {
    if (this.assetFiltersConfirmed.length > 0 || this.userFiltersConfirmed.length > 0) {
      return false;
    }
    return true;
  }

  get filteredAssets() {
    if (this.totalAssetsDictionary == null) {
      return [];
    }

    let formattedAssetsArray:any[] = [];
    if (this.assetFiltersConfirmed.length > 0 || this.userFiltersConfirmed.length > 0) {
      formattedAssetsArray = this.getFormattedAssets({ includeChildren: true });
    } else {
      formattedAssetsArray = this.getFormattedAssets();
    }

    if (this.assetFiltersConfirmed.length > 0) {
      formattedAssetsArray = this.filterRecordsByAssets(formattedAssetsArray);
    }
    if (this.userFiltersConfirmed.length > 0) {
      formattedAssetsArray = this.filterRecordsByUsers(formattedAssetsArray);
    }

    return formattedAssetsArray;
  }

  getFormattedAssets({ includeChildren } = { includeChildren: false }) {
    const formattedAssetsArray: any[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const [areaKey, areaValue] of Object.entries(this.totalAssetsDictionary.areas)) {
      formattedAssetsArray.push({
        level: 'area',
        asset_name: areaKey,
        // @ts-ignore
        subRows: areaValue.subRows,
      });
      // @ts-ignore
      if (!includeChildren && !areaValue.showChildren) {
        continue;
      }
      // @ts-ignore
      // eslint-disable-next-line no-restricted-syntax
      for (const [routeKey, routeValue] of Object.entries(areaValue.routes)) {
        formattedAssetsArray.push({
          level: 'route',
          area: areaKey,
          asset_name: routeKey,
          // @ts-ignore
          subRows: routeValue.subRows,
        });
        // @ts-ignore
        if (!includeChildren && !routeValue.showChildren) {
          continue;
        }

        // @ts-ignore
        // eslint-disable-next-line no-restricted-syntax
        for (const [padKey, padValue] of Object.entries(routeValue.pads)) {
          formattedAssetsArray.push({
            level: 'pad',
            area: areaKey,
            route: routeKey,
            asset_name: padKey,
            // @ts-ignore
            subRows: padValue.subRows,
          });
          // @ts-ignore
          if (!includeChildren && !padValue.showChildren) {
            continue;
          }

          // @ts-ignore
          // eslint-disable-next-line no-restricted-syntax
          for (const [wellKey, wellValue] of Object.entries(padValue.wells)) {
            formattedAssetsArray.push({
              level: 'well',
              area: areaKey,
              route: routeKey,
              pad: padKey,
              asset_name: wellKey,
              // @ts-ignore
              subRows: wellValue.subRows,
            });
          }
        }
      }
    }
    return formattedAssetsArray;
  }

    filterRecordsByAssets(assets) {
    const results:any[] = [];
    for (let x = 0; x < assets.length; x++) {
      let doesContainAsset = false;
      for (let y = 0; y < this.assetFiltersConfirmed.length; y++) {
        if (this.assetFiltersConfirmed[y].value === assets[x].asset_name) {
          doesContainAsset = true;
          break;
        }
      }
      if (doesContainAsset) {
        results.push(assets[x]);
      }
    }
    return JSON.parse(JSON.stringify(results));
  }

  filterRecordsByUsers(assets) {
    const results:any[] = [];
    for (let x = 0; x < assets.length; x++) {
      let doesContainUser = false;
      for (let z = 0; z < assets[x].subRows.length; z++) {
        for (let a = 0; a < assets[x].subRows[z].components.length; a++) {
          for (let y = 0; y < this.userFiltersConfirmed.length; y++) {
            if (this.userFiltersConfirmed[y].value === assets[x].subRows[z].components[a].Username) {
              doesContainUser = true;
              break;
            }
          }
        }
        if (doesContainUser) {
          results.push(assets[x]);
          break;
        }
      }
    }
    return JSON.parse(JSON.stringify(results));
  }

  async created() {
    await this.setUserOptions();
    await workflowModule.getNestedOperatorAssets();
    window.addEventListener('keydown', (e) => this.deleteAllSelectedComponents(e));
    window.addEventListener('mouseup', () => this.didEndSelectionWindow());

    this.loadingGanttChart = true;
    this.getShiftTemplates();

    scheduleModule.getColumnMappingData({});
    scheduleModule.resetScheduledRowComponents();
    scheduleModule.setActiveComponent(null);
    scheduleModule.resetPersonnelScheduleAssets();
    this.initializePage();
    this.originComponentList = [];

    this.userFiltersConfirmed = [];

    //

    if (localStorage.getItem('personnelScheduleUserFilter') != null) {
      this.userFiltersConfirmed = JSON.parse(localStorage.getItem('personnelScheduleUserFilter')!);
      this.userFiltersSelected = JSON.parse(JSON.stringify(this.userFiltersConfirmed));
    }

    if (localStorage.getItem('personnelScheduleAssetFilter') != null) {
      this.assetFiltersConfirmed = JSON.parse(localStorage.getItem('personnelScheduleAssetFilter')!);
      this.assetFiltersSelected = JSON.parse(JSON.stringify(this.assetFiltersConfirmed));
    }
 }

  async initializePage() {
    let startDatetime = new Date();
    startDatetime.setHours(0, 0, 0, 0);
    this.chartStartDatetime = startDatetime;

    scheduleModule.resetScheduledRowComponents();
    this.existingTableID = this.$route.params.table_id;
    workspaceModule.setActiveTable({ id: this.$route.params.table_id || '' });
        await assetsModule.getEnabledWells();

    startDatetime = new Date();
    const startDatetimeTemp = new Date();
    const minChartStartDate = new Date(startDatetime.setDate(startDatetime.getDate() - 7));
    const maxChartEndDate = new Date(startDatetimeTemp.setDate(startDatetimeTemp.getDate() + 14));

    const startDateString = `${minChartStartDate.getUTCFullYear()}-${
      addNumberPadding(minChartStartDate.getUTCMonth() + 1, 2, '0')}-${
        addNumberPadding(minChartStartDate.getUTCDate(), 2, '0')}T${
          addNumberPadding(minChartStartDate.getUTCHours(), 2, '0')}:${
            addNumberPadding(minChartStartDate.getUTCMinutes(), 2, '0')}:${
              addNumberPadding(minChartStartDate.getUTCSeconds(), 2, '0')}`;
    const endDateString = `${maxChartEndDate.getUTCFullYear()}-${
      addNumberPadding(maxChartEndDate.getUTCMonth() + 1, 2, '0')}-${
        addNumberPadding(maxChartEndDate.getUTCDate(), 2, '0')}T${
          addNumberPadding(maxChartEndDate.getUTCHours(), 2, '0')}:${
            addNumberPadding(maxChartEndDate.getUTCMinutes(), 2, '0')}:${
              addNumberPadding(maxChartEndDate.getUTCSeconds(), 2, '0')}`;

    await scheduleModule.getPersonnelScheduledShiftsGantt(
      { schedule_id: scheduleModule.activePersonnelSchedule.ID, start_date: startDateString, end_date: endDateString },
    );

        this.reloadPage();
    await this.setUserOptions();
  }

  async reloadPage() {
    this.loadingGanttChart = true;
    this.dates = [];

    scheduleModule.resetScheduledRowComponents();

    await this.addDataToGanttChart();
    this.loadingGanttChart = false;
  }

  async addDataToGanttChart() {
    const startDatetime = new Date(this.chartStartDatetimeWithOffset().getTime());
    const startDatetimeTemp = new Date(this.chartStartDatetimeWithOffset().getTime());
    const endDatetime = new Date(startDatetimeTemp.setHours(startDatetimeTemp.getHours() + (this.viewTotalDays * 24)));
    endDatetime.setHours(0, 0, 0, 0);
    this.chartEndDateTime = new Date(endDatetime.getTime());

    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
    ];
    if (
      monthNames[this.chartStartDatetimeWithOffset().getMonth()]
      !== monthNames[endDatetime.getMonth()]
    ) {
      this.currentDate = `${monthNames[this.chartStartDatetimeWithOffset().getMonth()]} — ${
        monthNames[endDatetime.getMonth()]} ${this.chartStartDatetimeWithOffset().getFullYear()}`;
    } else {
      this.currentDate = `${monthNames[this.chartStartDatetimeWithOffset().getMonth()]} ${
        this.chartStartDatetimeWithOffset().getFullYear()}`;
    }

    const startDateString = `${startDatetime.getUTCFullYear()}-${
      addNumberPadding(startDatetime.getUTCMonth() + 1, 2, '0')}-${
        addNumberPadding(startDatetime.getUTCDate(), 2, '0')}T${
          addNumberPadding(startDatetime.getUTCHours(), 2, '0')}:${
            addNumberPadding(startDatetime.getUTCMinutes(), 2, '0')}:${
              addNumberPadding(startDatetime.getUTCSeconds(), 2, '0')}`;
    const endDateString = `${endDatetime.getUTCFullYear()}-${
      addNumberPadding(endDatetime.getUTCMonth() + 1, 2, '0')}-${
        addNumberPadding(endDatetime.getUTCDate(), 2, '0')}T${
          addNumberPadding(endDatetime.getUTCHours(), 2, '0')}:${
            addNumberPadding(endDatetime.getUTCMinutes(), 2, '0')}:${
              addNumberPadding(endDatetime.getUTCSeconds(), 2, '0')}`;

    // @ts-ignore
    await scheduleModule.getPersonnelScheduledShiftsGanttSubSelection(
      {
        schedule_id: scheduleModule.activePersonnelSchedule.ID,
        start_date: startDateString,
        end_date: endDateString,
      },
    );
    this.dates = [];
    scheduleModule.resetFilterScheduledRowComponents();

    // Show chart 30 days
    for (let days = 0; days < this.viewTotalDays; days++) {
      let dayDatetime = new Date(startDatetime);
      dayDatetime = new Date(dayDatetime.setDate(startDatetime.getDate() + days));
      const hoursArray: Date[] = [];
      const dayObject = {
        day_id: dayDatetime.toString(),
        day_short: dayDatetime.getDate(),
        day: dayDatetime,
        hours: hoursArray,
      };
      for (let hours = 0; hours < 24; hours++) {
        let hourDatetime = new Date(dayDatetime);
        hourDatetime = new Date(hourDatetime.setHours(dayDatetime.getHours() + hours));
        dayObject.hours.push(hourDatetime);
      }
      this.dates.push(dayObject);
    }

    await this.reshuffleFilterScheduleRowComponents();

    const totalResults: any[] = [];
    const formattedAssets = this.getFormattedAssets({ includeChildren: true });
    for (let x = 0; x < formattedAssets.length; x++) {
      totalResults.push({ text: formattedAssets[x].asset_name, value: formattedAssets[x].asset_name });
    }
    this.totalAssets = totalResults;

    this.reloadGanttChartKey += 1;
    for (let z = 0; z < this.totalAssets.length; z++) {
      this.assetSpeedDictVals[this.totalAssets[z].text] = this.totalAssets[z];
    }
  }

  clearRowShiftsClicked(componentName) {
    for (let x = 0; x < scheduleModule.totalComponents.length; x++) {
      if (scheduleModule.totalComponents[x].AssetType === 'Area') {
        if (componentName === scheduleModule.totalComponents[x].Asset) {
          scheduleModule.deleteScheduledComponent({ component_index: x, hidden: true, changed: true });
        }
      } else if (scheduleModule.totalComponents[x].AssetType === 'Route') {
        if (componentName === scheduleModule.totalComponents[x].Asset) {
          scheduleModule.deleteScheduledComponent({ component_index: x, hidden: true, changed: true });
        }
      } else if (scheduleModule.totalComponents[x].AssetType === 'Pad') {
        if (componentName === scheduleModule.totalComponents[x].Asset) {
          scheduleModule.deleteScheduledComponent({ component_index: x, hidden: true, changed: true });
        }
      } else if (scheduleModule.totalComponents[x].AssetType === 'Well') {
        if (componentName === scheduleModule.totalComponents[x].Asset) {
          scheduleModule.deleteScheduledComponent({ component_index: x, hidden: true, changed: true });
        }
      }
    }

    scheduleModule.setActiveComponent(null);
    this.reshuffleFilterScheduleRowComponents();
    this.didHoverOverTitleCellID = null;
    this.didSelectTitleCellID = null;
  }

  async constructAssetsDictionary() {
    this.initialTotalAssetsDictionary = {
      areas: {},
    };

    if (
      workflowModule.nestedOperatorAssetsOutput === null
      || Object.keys(workflowModule.nestedOperatorAssetsOutput).length === 0
    ) {
      await workflowModule.getNestedOperatorAssets();
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const [areaKey, areaValue] of Object.entries(workflowModule.nestedOperatorAssetsOutput)) {
        let areaUUID = uuidv4();
        if (this.persistentIDs[areaKey] != null) {
          areaUUID = this.persistentIDs[areaKey];
        } else {
          this.persistentIDs[areaKey] = areaUUID;
        }

        if (this.initialTotalAssetsDictionary.areas[areaKey] == null) {
          this.initialTotalAssetsDictionary.areas[areaKey] = {
            routes: {},
            subRows: [{ components: [] }],
            showChildren: false,
          };
        }
        this.reverseAssetsDictionary[areaKey] = {
          level: 'Area',
        };

      // @ts-ignore
      // eslint-disable-next-line no-restricted-syntax
      for (const [routeKey, routeValue] of Object.entries(areaValue.Routes)) {
        let routeUUID = uuidv4();
        if (this.persistentIDs[routeKey] != null) {
          routeUUID = this.persistentIDs[routeKey];
        } else {
          this.persistentIDs[routeKey] = routeUUID;
        }
        if (this.initialTotalAssetsDictionary.areas[areaKey].routes[routeKey] == null) {
          this.initialTotalAssetsDictionary.areas[areaKey].routes[routeKey] = {
            pads: {},
            subRows: [{ components: [] }],
            showChildren: false,
          };
        }
        this.reverseAssetsDictionary[routeKey] = {
          level: 'Route',
          area: areaKey,
        };

        // @ts-ignore
        // eslint-disable-next-line no-restricted-syntax
        for (const [padKey, padValue] of Object.entries(routeValue.Pads)) {
            let padUUID = uuidv4();
            if (this.persistentIDs[padKey] != null) {
              padUUID = this.persistentIDs[padKey];
            } else {
              this.persistentIDs[padKey] = padUUID;
            }
            if (this.initialTotalAssetsDictionary.areas[areaKey].routes[routeKey].pads[padKey] == null) {
              this.initialTotalAssetsDictionary.areas[areaKey].routes[routeKey].pads[padKey] = {
                wells: {},
                subRows: [{ components: [] }],
                showChildren: false,
              };
            }
            this.reverseAssetsDictionary[padKey] = {
              level: 'Pad',
              area: areaKey,
              route: routeKey,
            };

          // @ts-ignore
          // eslint-disable-next-line no-restricted-syntax
          for (const [wellKey] of Object.entries(padValue.Wells)) {
              let wellUUID = uuidv4();
              if (this.persistentIDs[wellKey] != null) {
                wellUUID = this.persistentIDs[wellKey];
              } else {
                this.persistentIDs[wellKey] = wellUUID;
              }

            this.initialTotalAssetsDictionary.areas[areaKey].routes[routeKey].pads[padKey].wells[wellKey] = {
              subRows: [{ components: [] }],
            };
            this.reverseAssetsDictionary[wellKey] = {
              level: 'Well',
              area: areaKey,
              route: routeKey,
              pad: padKey,
            };
          }
        }
      }
    }
  }

  checkIfcomponentMatchesUserInPreviousSubrow(subrows, shift) {
    for (let x = 0; x < subrows.length; x++) {
      if (subrows[x].components.length === 0) {
        continue;
      }
      const dayBefore = new Date(shift.StartTime);
      dayBefore.setDate(dayBefore.getDate() - 1);
      if (
        subrows[x].components[subrows[x].components.length - 1].Username === shift.Username
        && this.sameDay(
          new Date(subrows[x].components[subrows[x].components.length - 1].StartTime), dayBefore,
        )
      ) {
        return x;
      }
    }
    return null;
  }

  async reshuffleFilterScheduleRowComponents() {
    if (this.totalAssetsDictionary === null || this.totalAssetsDictionary === undefined) {
      await this.constructAssetsDictionary();
    }
    let oldAssetDict:any = null;
    if (this.totalAssetsDictionary != null) {
      oldAssetDict = JSON.parse(JSON.stringify(this.totalAssetsDictionary));
    }
    this.totalAssetsDictionary = JSON.parse(JSON.stringify(this.initialTotalAssetsDictionary));
    if (oldAssetDict != null) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [areaKey, areaValue] of Object.entries(oldAssetDict.areas)) {
        // @ts-ignore
        if (areaValue.showChildren) {
          this.totalAssetsDictionary.areas[areaKey].showChildren = true;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const [routeKey, routeValue] of Object.entries(oldAssetDict.areas[areaKey].routes)) {
          // @ts-ignore
          if (routeValue.showChildren) {
            this.totalAssetsDictionary.areas[areaKey].routes[routeKey].showChildren = true;
          }

          // eslint-disable-next-line no-restricted-syntax
          for (const [padKey, padValue] of Object.entries(oldAssetDict.areas[areaKey].routes[routeKey].pads)) {
            // @ts-ignore
            if (padValue.showChildren) {
              this.totalAssetsDictionary.areas[areaKey].routes[routeKey].pads[padKey].showChildren = true;
            }

            // eslint-disable-next-line no-restricted-syntax
            for (const [wellKey, wellValue] of Object.entries(
              oldAssetDict.areas[areaKey].routes[routeKey].pads[padKey].wells,
            )) {
              // @ts-ignore
              if (wellValue.showChildren) {
                this.totalAssetsDictionary.areas[areaKey]
                .routes[routeKey].pads[padKey].wells[wellKey].showChildren = true;
              }
            }
          }
        }
      }
    }

    for (let y = 0; y < this.totalComponents.length; y++) {
      const copiedStart = new Date(new Date(`${this.totalComponents[y].StartTime}Z`).getTime());

      if (
        this.chartEndDateTime.getTime() <= copiedStart.getTime()
        || copiedStart.getTime() < this.chartStartDatetimeWithOffset().getTime()
      ) {
        continue;
      }

      let currentAsset:any = null;
      if (this.totalComponents[y].AssetType === 'Area') {
        currentAsset = this.totalAssetsDictionary.areas[this.totalComponents[y].Asset];
      } else if (this.totalComponents[y].AssetType === 'Route'
        && this.reverseAssetsDictionary[this.totalComponents[y].Asset] !== undefined) {
        currentAsset = this.totalAssetsDictionary.areas[this.reverseAssetsDictionary[
          this.totalComponents[y].Asset].area].routes[this.totalComponents[y].Asset];
      } else if (this.totalComponents[y].AssetType === 'Pad'
        && this.reverseAssetsDictionary[this.totalComponents[y].Asset] !== undefined) {
        currentAsset = this.totalAssetsDictionary.areas[this.reverseAssetsDictionary[
          this.totalComponents[y].Asset].area].routes[this.reverseAssetsDictionary[
          this.totalComponents[y].Asset].route].pads[this.totalComponents[y].Asset];
      } else if (this.totalComponents[y].AssetType === 'Well'
        && this.reverseAssetsDictionary[this.totalComponents[y].Asset] !== undefined) {
        currentAsset = this.totalAssetsDictionary.areas[this.reverseAssetsDictionary[
          this.totalComponents[y].Asset].area].routes[this.reverseAssetsDictionary[
          this.totalComponents[y].Asset].route].pads[this.reverseAssetsDictionary[
          this.totalComponents[y].Asset].pad].wells[this.totalComponents[y].Asset];
      }
      if (currentAsset === undefined || currentAsset === null) {
        continue;
      }

      if (currentAsset.subRows.length === 0) {
        currentAsset.subRows.push(this.totalComponents);
      } else {
        const appendToSubrowIndex = this.checkIfcomponentMatchesUserInPreviousSubrow(
          currentAsset.subRows, this.totalComponents[y],
        );
        if (appendToSubrowIndex !== null) {
          currentAsset.subRows[appendToSubrowIndex].components.push(this.totalComponents[y]);
        } else {
          // # Check if component ovrerlaps
          for (let z = 0; z < currentAsset.subRows.length; z++) {
            const row = currentAsset.subRows[z];
            const doesComponentOverlapInRow = this.doesComponentOverlapInRow(row, this.totalComponents[y]);

            if (doesComponentOverlapInRow && z < (currentAsset.subRows.length - 1)) {
              continue;
            } else if (!doesComponentOverlapInRow) {
              currentAsset.subRows[z].components.push(this.totalComponents[y]);
              break;
            } else if (doesComponentOverlapInRow && z === (currentAsset.subRows.length - 1)) {
              currentAsset.subRows.push({
                ...this.totalComponents[y],
                components: [this.totalComponents[y]],
              });

              break;
            }
          }
        }
      }

      if (this.totalComponents[y].AssetType === 'Area') {
        this.totalAssetsDictionary.areas[this.totalComponents[y].Asset] = currentAsset;
      } else if (this.totalComponents[y].AssetType === 'Route') {
        this.totalAssetsDictionary.areas[
          this.reverseAssetsDictionary[this.totalComponents[y].Asset].area
        ].routes[this.totalComponents[y].Asset] = currentAsset;
      } else if (this.totalComponents[y].AssetType === 'Pad') {
        this.totalAssetsDictionary.areas[
          this.reverseAssetsDictionary[this.totalComponents[y].Asset].area
        ].routes[this.reverseAssetsDictionary[
          this.totalComponents[y].Asset].route
        ].pads[this.totalComponents[y].Asset] = currentAsset;
      } else if (this.totalComponents[y].AssetType === 'Well') {
        this.totalAssetsDictionary.areas[
          this.reverseAssetsDictionary[this.totalComponents[y].Asset].area
        ].routes[this.reverseAssetsDictionary[
          this.totalComponents[y].Asset
        ].route].pads[
          this.reverseAssetsDictionary[this.totalComponents[y].Asset].pad
        ].wells[this.totalComponents[y].Asset] = currentAsset;
      }
    }

    this.reloadGanttChartKey += 1;
    this.totalAssetsDictionaryKey += 1;

    this.$emit('did-modify-schedule');
  }
  // Create a dictionary of

  resetActiveTitleRowOptionsDropdown() {
    this.didSelectTitleCellID = null;
  }

  didSelectTitleRowOptionsDropdown(id) {
    this.didSelectTitleCellID = id;
  }

  didSelectAssetDropdownLabel(asset) {
    if (asset.level === 'area') {
      return this.totalAssetsDictionary.areas[
        asset.asset_name].showChildren ? 'expand_more' : 'expand_less';
    } if (asset.level === 'route') {
      return this.totalAssetsDictionary.areas[
        asset.area].routes[asset.asset_name].showChildren ? 'expand_more' : 'expand_less';
    } if (asset.level === 'route') {
      return this.totalAssetsDictionary.areas[asset.area].routes[
        asset.route].pads[asset.asset_name].showChildren ? 'expand_more' : 'expand_less';
    } if (asset.level === 'well') {
      return '';
    }
    return 'expand_less';
  }

  didSelectAssetDropdown(asset) {
    if (asset.level === 'area') {
      this.totalAssetsDictionary.areas[asset.asset_name].showChildren = !this.totalAssetsDictionary.areas[
        asset.asset_name].showChildren;
    } else if (asset.level === 'route') {
      this.totalAssetsDictionary.areas[asset.area].routes[
        asset.asset_name].showChildren = !this.totalAssetsDictionary.areas[asset.area].routes[
        asset.asset_name].showChildren;
    } else if (asset.level === 'pad') {
      this.totalAssetsDictionary.areas[asset.area].routes[asset.route].pads[
        asset.asset_name].showChildren = !this.totalAssetsDictionary.areas[asset.area].routes[
        asset.route].pads[asset.asset_name].showChildren;
    } else if (asset.level === 'well') {
      this.totalAssetsDictionary.areas[asset.area].routes[asset.route].pads[
        asset.pad].wells[asset.asset_name].showChildren = !this.totalAssetsDictionary.areas[asset.area].routes[
        asset.route].pads[asset.pad].wells[asset.asset_name].showChildren;
    }
  }

  async didStartSelectionWindow(e) {
    this.totalSelectedComponents = {};
    if (!this.isDraggingElement) {
      this.isSelectingChartArea = true;

      if (document.getElementById('selectionElementID') != null) {
        return;
      }

      document.getElementById('ganttChartSelectableDivID')!.style.zIndex = '50';
      const rect = e.target.getBoundingClientRect();
      const x = e.clientX - rect.left; // x position within the element.
      const y = e.clientY - rect.top;

      if (document.getElementById('selectionElementID') != null) {
        this.selectionElement = document.getElementById('selectionElementID')!;
      } else {
        this.selectionElement = document.createElement('div');
      }

      this.selectionElement.style.position = 'absolute';
      this.selectionElement.style.height = '0px';
      this.selectionElement.style.width = '0px';
      this.selectionElement.style.background = 'rgba(255,255,255,0.15)';
      this.selectionElement.style.border = 'solid 1px rgba(255,255,255,0.3)';
      this.selectionElement.style.left = `${x.toString()}px`;
      this.selectionElement.style.top = `${y.toString()}px`;
      this.selectionElement.style.zIndex = '40';
      this.selectionElement.id = 'selectionElementID';
      this.selectionElementStartPoint = { x, y };

      document.getElementById('ganttChartSelectableDivID')!.appendChild(this.selectionElement);
    }
  }

  getRowColorLeft(rowType) {
    if (rowType === 'area') {
      return 'background: rgba(0,0,0,0.0);';
    }
    if (rowType === 'route') {
      return 'background: rgba(0,0,0,0.1);';
    }
    if (rowType === 'pad') {
      return 'background: rgba(0,0,0,0.2);';
    }
    if (rowType === 'well') {
      return 'background: rgba(0,0,0,0.3);';
    }
    return 'background: rgba(0,0,0,0.0);';
  }

  getRowTitleWidth(rowType) {
    if (rowType === 'area') {
      return 'padding-left: 0px; width: 220px; ';
    }
    if (rowType === 'route') {
      return 'padding-left: 12px; width: 220px; ';
    }
    if (rowType === 'pad') {
      return 'padding-left: 24px; width: 220px; ';
    }
    if (rowType === 'well') {
      return 'padding-left: 36px; width: 220px; ';
    }
    return 'padding-left: 0px; width: 220px; ';
  }

  shouldHighlightComponent(id) {
    return this.totalSelectedComponents[id] != null;
  }

  shouldHighlightComponentByElement(elem) {
    const rect1 = elem.getBoundingClientRect();
    const rect2 = this.selectionElement.getBoundingClientRect();
    const overlap = !(rect1.right < rect2.left
                || rect1.left > rect2.right
                || rect1.bottom < rect2.top
                || rect1.top > rect2.bottom);

    return overlap;
  }

  async didEndSelectionWindow() {
    this.totalSelectedComponents = [];
    if (this.selectionElement != null) {
      const totalComponentsList = document.querySelectorAll('[isDraggableComponent="true"]');
      for (let x = 0; x < totalComponentsList.length; x++) {
        if (this.shouldHighlightComponentByElement(totalComponentsList[x])) {
          this.totalSelectedComponents[totalComponentsList[x].id] = totalComponentsList[x];
        }
      }
    }

    this.isSelectingChartArea = false;
    if (this.selectionElement != null) {
      this.reloadGanttChartComponentsKey++;
    }

    await sleep(300);
    // eslint-disable-next-line no-unused-expressions
    document.getElementById('selectionElementID')?.remove();
    if (document.getElementById('ganttChartSelectableDivID') != null) {
      document.getElementById('ganttChartSelectableDivID')!.style.zIndex = '20';
    }
    this.selectionElement = null;
  }

  didMoveMouse(e) {
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left; // x position within the element.
    const y = e.clientY - rect.top;
    if (this.isSelectingChartArea && !this.isDraggingElement) {
      if (x < this.selectionElementStartPoint.x && y > this.selectionElementStartPoint.y) {
        this.selectionElement.style.top = `${this.selectionElementStartPoint.y.toString()}px`;
        this.selectionElement.style.width = `${(this.selectionElementStartPoint.x - x).toString()}px`;
        this.selectionElement.style.height = `${(y - this.selectionElementStartPoint.y).toString()}px`;
        this.selectionElement.style.left = `${x.toString()}px`;
      } else if (x > this.selectionElementStartPoint.x && y > this.selectionElementStartPoint.y) {
        this.selectionElement.style.top = `${this.selectionElementStartPoint.y.toString()}px`;
        this.selectionElement.style.width = `${(x - this.selectionElementStartPoint.x).toString()}px`;
        this.selectionElement.style.height = `${(y - this.selectionElementStartPoint.y).toString()}px`;
        this.selectionElement.style.left = `${this.selectionElementStartPoint.x.toString()}px`;
      } else if (x < this.selectionElementStartPoint.x && y < this.selectionElementStartPoint.y) {
        this.selectionElement.style.top = `${y.toString()}px`;
        this.selectionElement.style.width = `${(this.selectionElementStartPoint.x - x).toString()}px`;
        this.selectionElement.style.height = `${(this.selectionElementStartPoint.y - y).toString()}px`;
        this.selectionElement.style.left = `${x.toString()}px`;
      } else if (x > this.selectionElementStartPoint.x && y < this.selectionElementStartPoint.y) {
        this.selectionElement.style.top = `${y.toString()}px`;
        this.selectionElement.style.width = `${(x - this.selectionElementStartPoint.x).toString()}px`;
        this.selectionElement.style.height = `${(this.selectionElementStartPoint.y - y).toString()}px`;
        this.selectionElement.style.left = `${this.selectionElementStartPoint.x.toString()}px`;
      }
    }
  }

  getDropdownTimeString(time) {
  function pad(n, width, z) {
    const znew = z || '0';
    const nnew = `${n}`;
    return nnew.length >= width ? nnew : new Array(width - nnew.length + 1).join(znew) + nnew;
  }
  if (time === undefined) {
    return '';
  }
  const hourString = parseInt(time.split(':')[0]);
  const minuteString = parseInt(time.split(':')[1]);
    if (hourString === 0) {
      return `12:${pad(minuteString, 2, '0')}am`;
    } if (hourString < 12) {
    return `${hourString}:${pad(minuteString, 2, '0')}am`;
    } if (hourString === 12) {
    return `12:${pad(minuteString, 2, '0')}pm`;
    } if (hourString <= 23) {
      return `${hourString - 12}:${pad(minuteString, 2, '0')}pm`;
    }
    return '';
  }

  getDayOfWeek(date) {
    const dayOfWeek = new Date(date).getDay();
    return Number.isNaN(dayOfWeek) ? null
    : ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][dayOfWeek];
  }

  getDayOfWeekStringFromDate(datetime) {
    return this.getDayOfWeek(datetime.day)?.toUpperCase();
  }

  getTextColorForComponent(templateID, email) {
  if (email) {
    return 'white';
  }
    return 'rgba(255,255,255,0.6)';
  }

  getColorForComponent(templateID, email) {
    if (templateID == null) {
      if (email) {
        return 'rgba(155,155,155,1)';
      }
        return 'rgba(155,155,155,0.5)';
    }
      for (let x = 0; x < this.allShiftTemplates.length; x++) {
        if (this.allShiftTemplates[x].ID === templateID) {
          if (email) {
            return this.allShiftTemplates[x].Color;
          }
            return this.allShiftTemplates[x].Color;
        }
      }

    if (email) {
      return 'rgba(155,155,155,1)';
    }
      return 'rgba(155,155,155,0.5)';
  }

  get assetFilterOptionsSpeedDictVals() {
    return this.assetSpeedDictVals;
  }

  get assetFilterOptionsVals() {
    return this.totalAssets;
  }

  get userFilterOptionsVals() {
    return accountModule.reassignmentList.filter((u) => u && u.email).map((i) => ({
      // @ts-ignore
      text: i.name || 'Test User',
      value: i.email || '',
    })).sort((a, b) => a.text.localeCompare(b.text));
  }

  deleteComponentSelected(componentRename) {
    for (let x = 0; x < scheduleModule.totalComponents.length; x++) {
      if (componentRename.ID === scheduleModule.totalComponents[x].ID) {
        scheduleModule.deleteScheduledComponent({ component_index: x, hidden: true, changed: true });
      }
    }
    this.popupBackground = false;
    scheduleModule.setActiveComponent(null);
    this.showChangeButtons = true;
    this.reshuffleFilterScheduleRowComponents();
  }

  hideDropdown() {
    this.dropdownLocation = [];
    if (this.dropdownTypeShowing === 'AssetsFilter') {
      this.assetFiltersSelected = JSON.parse(JSON.stringify(this.assetFiltersConfirmed));
    }
    if (this.dropdownTypeShowing === 'UsersFilter') {
      this.userFiltersSelected = JSON.parse(JSON.stringify(this.userFiltersConfirmed));
    }
  }

  confirmDropdown() {
    if (this.dropdownTypeShowing === 'AssetsFilter') {
      this.assetFiltersConfirmed = JSON.parse(JSON.stringify(this.assetFiltersSelected));
      localStorage.setItem('personnelScheduleAssetFilter', JSON.stringify(this.assetFiltersConfirmed));
    }

    if (this.dropdownTypeShowing === 'UsersFilter') {
      this.userFiltersConfirmed = JSON.parse(JSON.stringify(this.userFiltersSelected));
      localStorage.setItem('personnelScheduleUserFilter', JSON.stringify(this.userFiltersConfirmed));
    }
  }

  didSelectAssetFilterDropdown(e) {
    const rect = e.srcElement.getBoundingClientRect();
    this.dropdownLocation = [rect.x - 0, rect.y + 38];
    this.dropdownTypeShowing = 'AssetsFilter';
  }

  didSelectUsersFilterDropdown(e) {
    const rect = e.srcElement.getBoundingClientRect();
    this.dropdownLocation = [rect.x - 0, rect.y + 38];
    this.dropdownTypeShowing = 'UsersFilter';
  }

  async deleteShiftTemplate(templateID) {
    this.isDeletingTemplateByID = templateID;
    await scheduleModule.deleteShiftTemplate({ id: templateID });
    this.shiftTemplates = await scheduleModule.getShiftTemplates();

    this.isDeletingTemplateByID = '';
    this.isDeletingTemplate = false;
    this.isHoveringOverTemplateID = '';
  }

  async editShiftTemplate() {
    this.shiftTemplates = await scheduleModule.getShiftTemplates();

    scheduleModule.setActiveShiftTemplateEdit({ template: null });
    this.isHoveringOverTemplateID = '';
  }

  get activeShiftTemplateEdit() {
    return scheduleModule.activeShiftTemplateEdit;
  }

  didClickToEditTemplate(template) {
    if (scheduleModule.activeShiftTemplateEdit !== null && scheduleModule.activeShiftTemplateEdit.ID === template.ID) {
      scheduleModule.setActiveShiftTemplateEdit({ template: null });
    } else {
      scheduleModule.setActiveShiftTemplateEdit({ template });
    }
  }

  didClickToDeleteTemplate(id) {
    if (this.isDeletingTemplate === id) {
      this.isDeletingTemplate = false;
    } else {
      this.isDeletingTemplate = id;
    }
  }

  get editGroupDetails() {
    return scheduleModule.editGroupDetails;
  }

  get scheduleColumnWidth() {
    return SCHEDULE_CHART_DAY_WIDTH;
  }

  getScheduleRowHeight(rowIndex, totalRows) {
    return rowIndex === (totalRows - 1) ? SCHEDULE_ROW_HEIGHT : SCHEDULE_ROW_STACKED_HEIGHT;
  }

  get activeComponent() {
    return scheduleModule.activeComponent;
  }

    get currentDay(): number {
        return new Date().getDate();
    }

    get totalComponents(): any[] {
        return scheduleModule.totalComponents;
    }

  get allShiftTemplates() {
    return this.shiftTemplates;
  }

  copyAllSelectedElements() {
    for (let x = 0; x < scheduleModule.totalComponents.length; x++) {
        // eslint-disable-next-line no-restricted-syntax
        for (const [key] of Object.entries(this.totalSelectedComponents)) {
          if (scheduleModule.totalComponents[x].ID === key.replace('group_row_component_', '')) {
            const localComponent = JSON.parse(JSON.stringify(scheduleModule.totalComponents[x]));
            localComponent.ID = uuidv4();
            // eslint-disable-next-line no-self-assign
            localComponent.is_from_edit = localComponent.is_from_edit;
            localComponent.changed = true;
            localComponent.type = 'new';
            scheduleModule.addFilterScheduledComponent({ data: localComponent });
          }
        }
    }
    this.reshuffleFilterScheduleRowComponents();
  }

  deleteAllSelectedComponents(e) {
      const enew = e || window.event;
      const key = enew.which || enew.keyCode; // keyCode detection
      const ctrl = enew.ctrlKey ? enew.ctrlKey : ((key === 17)); // ctrl detection

      const command = enew.metaKey ? enew.metaKey : ((key === 91)); // ctrl detection

      if (key === 86 && ctrl) {
        this.copyAllSelectedElements();
      } else if (key === 86 && command) {
        this.copyAllSelectedElements();
      }

      if (key === 8 || key === 46) {
        if (Object.keys(this.totalSelectedComponents).length === 0) {
          return;
        }
        const components: any[] = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const [key2] of Object.entries(this.totalSelectedComponents)) {
          components.push(key2.replace('group_row_component_', ''));
        }
        this.deleteComponentsSelected(components);
        this.totalSelectedComponents = {};
      }
  }

  deleteComponentsSelected(componentRenames) {
    for (let x = 0; x < this.totalComponents.length; x++) {
    for (let a = 0; a < componentRenames.length; a++) {
      const componentRename = componentRenames[a];
      if (componentRename === this.totalComponents[x].ID) {
        scheduleModule.deleteScheduledComponent({ component_index: x, hidden: true, changed: true });
      }
    }
    }
    this.reshuffleFilterScheduleRowComponents();
  }

  async getShiftTemplates() {
    this.shiftTemplates = await scheduleModule.getShiftTemplates();
  }

  async setUserOptions() {
    const items = await accountModule.getReassignmentList({ useExisting: true });
    scheduleModule.setAccountDetails(items);
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  closeEditGroupDetailsPopup() {
    this.popupBackground = false;
    scheduleModule.setEditGroupDetails(null);
  }

  calculateTotalRowHeight(rowCount) {
    return ((SCHEDULE_ROW_HEIGHT - SCHEDULE_ROW_STACKED_HEIGHT) * (rowCount - 1)) + SCHEDULE_ROW_HEIGHT;
  }

  sameDay(d1, d2) {
  return d1.getFullYear() === d2.getFullYear()
    && d1.getMonth() === d2.getMonth()
    && d1.getDate() === d2.getDate();
  }

  fetchGetNameByEmail(email) {
    return getNameByEmail(email);
  }

  callGetNameByEmail(previousComponent, currentComponent, email) {
    if (previousComponent != null) {
      const startDate = new Date(currentComponent.StartTime);
      const nextDayStartDate = new Date(new Date(currentComponent.StartTime).getTime());
      const checkStartDate = new Date(previousComponent.StartTime);
      nextDayStartDate.setDate(nextDayStartDate.getDate() - 1);
      startDate.setDate(startDate.getDate() - 1);
      if (
        (nextDayStartDate.getTime() > this.chartStartDatetimeWithOffset().getTime())
        && this.sameDay(startDate, checkStartDate) && currentComponent.Username === previousComponent.Username
        && currentComponent.TemplateID === previousComponent.TemplateID && currentComponent.TemplateID != null
      ) {
        return '';
      }
    }

    if (email && email.toLowerCase() !== 'unassigned') {
      const fullName = getNameByEmail(email).split(' ');
      return fullName.length > 1 ? `${fullName[0][0]}.${fullName[1][0]}.` : fullName[0];
    }
    return 'Unassigned';
  }

  chartStartDatetimeWithOffset() {
    const modifyDate = new Date(this.chartStartDatetime.getTime());
    return new Date(modifyDate.setHours(modifyDate.getHours() - SCHEDULE_START_DATE_OFFSET_HOURS));
  }

  async weekArrowSelected(direction) {
    const tempStartDate = new Date(this.chartStartDatetime.getTime());
    let startDatetime;
    if (direction.toLowerCase() === 'left') {
      this.isLoadingLeftArrow = true;
      await this.sleep(50);
      startDatetime = new Date(tempStartDate.setHours(tempStartDate.getHours() - (7 * 24)));
    } else {
      this.isLoadingRightArrow = true;
      await this.sleep(50);
      startDatetime = new Date(tempStartDate.setHours(tempStartDate.getHours() + (7 * 24)));
    }

    startDatetime.setHours(0, 0, 0, 0);

    this.chartStartDatetime = startDatetime;
    await this.reloadPage();
    this.isLoadingRightArrow = false;
    this.isLoadingLeftArrow = false;
  }

  didSelectToday() {
    const startDatetime = new Date(new Date().setHours(new Date().getHours()));
    startDatetime.setHours(0, 0, 0, 0);

    this.chartStartDatetime = startDatetime;
    this.reloadPage();
  }

  showComponentDetails(component) {
    if (component != null) {
      this.popupBackground = true;
      scheduleModule.setActiveComponent(component);
    }
  }

  addNewJobClicked() {
    this.addNewJobPopupInnerShowing = true;
    this.popupBackground = true;
  }

  hideAddNewJobClicked() {
    this.addNewJobPopupInnerShowing = false;
    this.popupBackground = false;
  }

  getComponentLeftPosition(startDate) {
    if (startDate.indexOf('undefined') !== -1) return '0';

    const date:any = new Date(`${startDate}Z`);
    // @ts-ignore
    const daysDiff = Math.floor((date - this.chartStartDatetimeWithOffset()) / (1000 * 60 * 60 * 24));
    return `${(daysDiff * SCHEDULE_CHART_DAY_WIDTH).toString()}px`;
  }

  getComponentWidth(startDate, endDate) {
    if (startDate.indexOf('undefined') !== -1 || endDate.indexOf('undefined') !== -1) return '0';
    const s:any = new Date(`${startDate}Z`);
    const e:any = new Date(`${endDate}Z`);
    const widthDaysDiff = Math.floor((e - s - 1) / (1000 * 60 * 60 * 24));
    s.setDate(s.getDate() + widthDaysDiff - 1);
    return `${(SCHEDULE_CHART_DAY_WIDTH).toString()}px`;
  }

  async publishChangesClicked() {
    this.isUpdatingScheduleView = true;
    this.setSavingDetailsBannerID('Saving updated schedule (this may take a minute)');
    const promises: any[] = [];
    const publishDeleteCustomShift: any[] = [];
    const publishPostCustomShift: any[] = [];
    const publishUpdateShift: any[] = [];
    for (let i = 0; i < scheduleModule.totalComponents.length; i++) {
      if (scheduleModule.totalComponents[i].should_delete && scheduleModule.totalComponents[i].type !== 'new') {
        publishDeleteCustomShift.push({ ShiftID: scheduleModule.totalComponents[i].ID });
      } else if (!scheduleModule.totalComponents[i].should_delete && scheduleModule.totalComponents[i].type === 'new') {
        if (
            scheduleModule.totalComponents[i].is_from_edit === undefined
            || scheduleModule.totalComponents[i].is_from_edit === null
        ) {
          publishPostCustomShift.push({
            date: scheduleModule.totalComponents[i].StartTime,
            startTime: scheduleModule.totalComponents[i].StartTime,
            endTime: scheduleModule.totalComponents[i].EndTime,
            username: scheduleModule.totalComponents[i].Username,
            assetType: scheduleModule.totalComponents[i].AssetType,
            asset: scheduleModule.totalComponents[i].Asset,
            recurType: '',
            recurStartDate: null,
            recurEndDate: null,
            weekdays: null,
            onDays: null,
            offDays: null,
            workspace_id: scheduleModule.activePersonnelWorkspace.ID,
            schedule_id: scheduleModule.activePersonnelSchedule.ID,
            RowID: scheduleModule.totalComponents[i].Asset,
            shiftTemplateID: scheduleModule.totalComponents[i].TemplateID,
            operator: getConfigEnv('OPERATOR_LOWERCASED'),
          });
        } else {
          publishPostCustomShift.push({
            date: scheduleModule.totalComponents[i].is_from_edit.date,
            startTime: scheduleModule.totalComponents[i].StartTime,
            endTime: scheduleModule.totalComponents[i].EndTime,
            username: scheduleModule.totalComponents[i].Username,
            assetType: scheduleModule.totalComponents[i].AssetType,
            asset: scheduleModule.totalComponents[i].Asset,
            recurType: null,
            recurStartDate: null,
            recurEndDate: null,
            weekdays: null,
            onDays: null,
            offDays: null,
            workspace_id: scheduleModule.totalComponents[i].is_from_edit.workspace_id,
            schedule_id: scheduleModule.totalComponents[i].is_from_edit.schedule_id,
            RowID: scheduleModule.totalComponents[i].is_from_edit.RowID,
            shiftTemplateID: scheduleModule.totalComponents[i].is_from_edit.shiftTemplateID,
            operator: getConfigEnv('OPERATOR_LOWERCASED'),
          });
        }
      } else if (scheduleModule.totalComponents[i].changed && scheduleModule.totalComponents[i].type !== 'new') {
        publishUpdateShift.push({
          username: scheduleModule.totalComponents[i].Username,
          startTime: scheduleModule.totalComponents[i].StartTime,
          endTime: scheduleModule.totalComponents[i].EndTime,
          assetType: scheduleModule.totalComponents[i].AssetType,
          asset: scheduleModule.totalComponents[i].Asset,
          ShiftID: scheduleModule.totalComponents[i].ID,
          TemplateID: scheduleModule.totalComponents[i].TemplateID,
          operator: getConfigEnv('OPERATOR_LOWERCASED'),
        });
      }
    }

    if (publishDeleteCustomShift.length > 0) {
      promises.push(scheduleModule.deleteCustomShiftBatch({
        deleteShifts: publishDeleteCustomShift,
      }));
    }

    if (publishUpdateShift.length > 0) {
      promises.push(scheduleModule.putCustomShiftBatch({
        putShifts: publishUpdateShift,
      }));
    }

    if (publishPostCustomShift.length > 0) {
      promises.push(scheduleModule.postCustomShiftBatch({
        newShifts: publishPostCustomShift,
      }));
    }

    Promise.all(promises).then(() => {
      this.removeSavingDetailsBannerID(5000, 'Your changes have been published');
      this.initializePage();
      this.$emit('did-modify-schedule');
      this.isUpdatingScheduleView = false;
    }).catch(() => {
      this.initializePage();
      this.$emit('did-modify-schedule');
      this.isUpdatingScheduleView = false;
    });
  }

  closeConfirmDialog() {
    this.confirmDialogShowing = null;
  }

  // Pulled in from outside
  getComponentDateRangeFromChartLocation(component) {
    const targetElementPos = getElementPosition(component, 'DRAGGED');
    const targetElementMinX = targetElementPos.x;
    const targetElementMaxX = targetElementPos.x + component.getBoundingClientRect().width;

    const newStartOfDay = (
      Math.floor((targetElementMinX - SCHEDULE_PIXELS_TO_CURRENT_DAY) / SCHEDULE_CHART_DAY_WIDTH)
      + this.chartStartDatetimeWithOffset().getDate()
      );
    const newEndOfDay = (
      Math.floor((targetElementMaxX - SCHEDULE_PIXELS_TO_CURRENT_DAY) / SCHEDULE_CHART_DAY_WIDTH)
      + this.chartStartDatetimeWithOffset().getDate()
      );
    const firstDayStart = new Date(
      this.chartStartDatetimeWithOffset().getFullYear(), this.chartStartDatetimeWithOffset().getMonth(), 1,
    );
    const firstDayEnd = new Date(
      this.chartStartDatetimeWithOffset().getFullYear(), this.chartStartDatetimeWithOffset().getMonth(), 1,
    );
    const newStartDate = firstDayStart.setDate((firstDayStart.getDate()) + (newStartOfDay + 1));
    const newEndDate = firstDayEnd.setDate((firstDayEnd.getDate()) + (newEndOfDay + 1));

    return [new Date(newStartDate), new Date(newEndDate)];
  }

  getClosestRowIDOnDragEnd(element) {
    const elementCenter = getElementCenterPosition(element, 'DRAGGED');
    const ganttChartDateTitleLabeldID = document.getElementById('ganttChartDateTitleLabeldID');
    const maxYDatesLabel = getElementCenterPosition(ganttChartDateTitleLabeldID, 'DRAGGED').y
    + ganttChartDateTitleLabeldID!.getBoundingClientRect().height;
    let rowID = null;

    if (elementCenter.y <= maxYDatesLabel) {
      // @ts-ignore
      return `0<>${document.getElementById('ganttChartScrollableContentID')!.childNodes[1].id.replace(
          'group_row_wrapper_', '',
        )
      }`;
    }

    for (let x = 0; x < this.filteredAssets.length; x++) {
      const filterScheduledRowComponent = this.filteredAssets[x];
      for (let y = 0; y < filterScheduledRowComponent.subRows.length; y++) {
        // @ts-ignore
        rowID = `${y.toString()}<>${filterScheduledRowComponent.asset_name}`;
        const rowElement = document.getElementById(rowID!) !;
        if (rowElement == null) {
          continue;
        }
        const rowMinY = getElementCenterPosition(rowElement, 'ROW').y
        - ((rowElement.getBoundingClientRect().height / 2) + 15);
        const rowMaxY = getElementCenterPosition(rowElement, 'ROW').y
        + ((rowElement.getBoundingClientRect().height / 2) + 45);
        if (elementCenter.y > rowMinY && elementCenter.y < rowMaxY) {
          return rowID;
        }
      }
    }
    return rowID;
  }

  getAllChartComponents(ignoringDraggedComponent: any = null) {
    const totalComponentsList = document.querySelectorAll('[isDraggableComponent="true"]');
    if (ignoringDraggedComponent != null) {
      const otherComponents: any[] = [];
      for (let x = 0; x < totalComponentsList.length; x++) {
        if (
          ignoringDraggedComponent!.getAttribute('componentID')
          === totalComponentsList[x].getAttribute('componentID')
        ) {
          continue;
        }
        otherComponents.push(totalComponentsList[x]);
      }
      return otherComponents;
    }
    return totalComponentsList;
  }

// ***********************************************
// ********** Update component params ************
// ***********************************************

  async updatePersonnelWorkspaceSched() {
    await scheduleModule.updatePersonnelWorkspaceSched();
  }

  async addDataToGanttAsync() {
    await this.addDataToGanttChart();
    scheduleModule.resetPersonnelScheduleShifts();
    this.isUpdatingScheduleView = false;
    this.removeSavingDetailsBannerID(5000, 'Your changes have been saved');
  }

  setSavingDetailsBannerID(text) {
    this.savingChartComponentChange = true;
    this.showSavingDetailsBannerID = true;
    this.setSavingDetailsBannerIDText(text);
  }

  setSavingDetailsBannerIDText(text) {
    this.savingDetailsBannerIDText = text;
  }

  async removeSavingDetailsBannerID(wait = 5000, text = '') {
    this.savingChartComponentChange = false;
    if (text !== '') {
      this.setSavingDetailsBannerIDText(text);
    }
    await sleep(wait);
    this.showSavingDetailsBannerID = false;
    this.savingDetailsBannerIDText = '';
  }

/*
   ***********************************************
   ********* Handle dragged components ***********
   ***********************************************
*/

  pos1 = 0

  pos2 = 0

  pos3 = 0

  pos4 = 0

  draggedElement

  originComponentList

  isDraggingElement = false

  addOriginComponent(component) {
    for (let index = 0; index < this.originComponentList.length; index++) {
      if (this.originComponentList[index].id === component.id) return;
    }
    this.originComponentList.push(component);
  }

  startDrag(e, component) {
    this.isDraggingElement = true;
    this.isSelectingChartArea = false;
    this.dragMouseDown(e, component.ID);
    // eslint-disable-next-line no-param-reassign
    component.changed = true;
    // eslint-disable-next-line no-param-reassign
    component.selected = true;
    for (let x = 0; x < scheduleModule.totalScheduledRowComponents.length; x++) {
      for (let y = 0; y < scheduleModule.totalScheduledRowComponents[x].rows.length; y++) {
        for (let z = 0; z < scheduleModule.totalScheduledRowComponents[x].rows[y].components.length; z++) {
          if (scheduleModule.totalScheduledRowComponents[x].rows[y].components[z].ID
            === component.ID) {
            scheduleModule.totalScheduledRowComponents[x].rows[y].components[z].selected = true;
            scheduleModule.totalScheduledRowComponents[x].rows[y].components[z].changed = true;
            return;
          }
        }
      }
    }
  }

  compare(a, b) {
    if ((new Date(a.start_date)) < (new Date(b.start_date))) {
      return -1;
    }
    if ((new Date(a.start_date)) > (new Date(b.start_date))) {
      return 1;
    }
    return 0;
  }

  doesComponentOverlapInRow(row, component) {
    if (component.hidden) {
      return false;
    }
    for (let x = 0; x < row.components.length; x++) {
      const checkComponent = row.components[x];
      if (checkComponent.ID === component.ID) {
        continue;
      }
      const copiedStart = new Date(new Date(`${component.StartTime}Z`).getTime());
      const copiedEnd = new Date(new Date(`${component.StartTime}Z`).getTime());
      copiedEnd.setDate(copiedEnd.getDate() + 1);
      const copiedCheckStart = new Date(new Date(`${checkComponent.StartTime}Z`).getTime());
      const copiedCheckEnd = new Date(new Date(`${checkComponent.StartTime}Z`).getTime());
      copiedCheckEnd.setDate(copiedCheckEnd.getDate() + 1);
      if (this.sameDay(copiedStart, copiedCheckStart) && !checkComponent.hidden) {
        return true;
      }
    }
    return false;
  }

  draggableParentSelectedWrapper;

  dragMouseDown(e, componentID) {
    const enew = e || window.event;
    enew.preventDefault();
    let targetElement = enew.target;
    if (!enew.target.hasAttribute('isDraggableComponent')) {
      targetElement = enew.target.parentElement;
    }

    if (Object.keys(this.totalSelectedComponents).length === 0) {
      this.draggedElement = targetElement;
    } else {
      const tempWrapperElement = document.createElement('div');

      tempWrapperElement.style.top = targetElement.style.top;
      tempWrapperElement.style.left = targetElement.style.left;
      tempWrapperElement.style.width = targetElement.style.width;
      tempWrapperElement.style.height = targetElement.style.height;
      tempWrapperElement.style.position = 'absolute';
      tempWrapperElement.id = 'tempSelectionWrapperElementID';

      let minX;
      let minY;
      let width;
      let height;
      targetElement.parentElement.appendChild(tempWrapperElement);
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.totalSelectedComponents)) {
        // @ts-ignore
        const rect1 = document.getElementById(key)!.getBoundingClientRect();
        if (!minX) {
          // @ts-ignore
          minX = parseInt(value.style.left.replace('px', ''));
          minY = rect1.top;
          // @ts-ignore
          width = rect1.left + (parseInt(value.style.width.replace('px', '')) * 2);
          // @ts-ignore
          height = rect1.top + parseInt(value.style.height.replace('px', ''));
        } else {
          // @ts-ignore
          if (parseInt(value.style.left.replace('px', '')) < minX) {
            // @ts-ignore
            minX = parseInt(value.style.left.replace('px', ''));
          }
          if (rect1.top < minY) {
            minY = rect1.top;
          }
          // @ts-ignore
          if (rect1.left + (parseInt(value.style.width.replace('px', '')) * 2) > width) {
            // @ts-ignore
            width = rect1.left + (parseInt(value.style.width.replace('px', '')) * 2);
          }
          // @ts-ignore
          if ((rect1.top + parseInt(value.style.height.replace('px', ''))) > height) {
            // @ts-ignore
            height = rect1.top + parseInt(value.style.height.replace('px', ''));
          }
        }
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key] of Object.entries(this.totalSelectedComponents)) {
        const element = document.getElementById(key)!;
        const rect1Temp = element.getBoundingClientRect();

        element.style.left = `${(parseInt(element.style.left.replace('px', '')) - minX).toString()}px`;
        element.style.top = `${(rect1Temp.top - minY).toString()}px`;
        // @ts-ignore
        tempWrapperElement.appendChild(element);
      }

      const tempWrapperElementRect = tempWrapperElement.getBoundingClientRect();
      tempWrapperElement.style.left = `${minX.toString()}px`;
      tempWrapperElement.style.width = `${(width - tempWrapperElementRect.left).toString()}px`;
      tempWrapperElement.style.height = `${(height - tempWrapperElementRect.top).toString()}px`;

      tempWrapperElement.style.top = `${
        (-(targetElement.getBoundingClientRect().top - tempWrapperElement.getBoundingClientRect().top)).toString()
      }px`;

      this.draggedElement = tempWrapperElement;
    }

    // get the mouse cursor position at startup:
    targetElement.style.zIndex = '20';

    this.addOriginComponent({
      id: componentID,
      self: this.draggedElement,
      left: this.draggedElement.style.left,
      parent: this.draggedElement.parentElement,
    });

    this.pos3 = e.clientX;
    this.pos4 = e.clientY;
    document.onmouseup = this.closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = this.elementDrag;
  }

  elementDrag(e) {
    if (this.isResizingElement) {
      return;
    }
    const enew = e || window.event;
    enew.preventDefault();
    // calculate the new cursor position:
    this.pos1 = this.pos3 - enew.clientX;
    this.pos2 = this.pos4 - enew.clientY;
    this.pos3 = enew.clientX;
    this.pos4 = enew.clientY;
    // set the element's new position:
    this.draggedElement.style.top = `${this.draggedElement.offsetTop - this.pos2}px`;
    this.draggedElement.style.left = `${this.draggedElement.offsetLeft - this.pos1}px`;
    this.didDragElement = true;
  }

  closeDragElement(e) {
    /* stop moving when mouse button is released: */
    this.isDraggingElement = false;
    document.onmouseup = null;
    document.onmousemove = null;
    if (!this.didDragElement) {
      return;
    }
    const components = this.getAllChartComponents(e.target);

    for (let x = 0; x < components.length; x++) {
      components[x].style.zIndex = '20';
    }
    this.didDragElement = false;
    const tempSelectionWrapperElementID = document.getElementById('tempSelectionWrapperElementID');
    if (tempSelectionWrapperElementID != null) {
      this.handleDraggedComponentsWrapper();
    } else {
      this.handleDraggedComponent(e);
    }
    this.reshuffleFilterScheduleRowComponents();
    this.isDraggingElement = false;
  }

  handleDraggedComponentsWrapper() {
    function pad(n, width, z) {
      const znew = z || '0';
      const nnew = `${n}`;
      return nnew.length >= width ? nnew : new Array(width - nnew.length + 1).join(znew) + nnew;
    }

    for (let filterX = 0; filterX < this.totalComponents.length; filterX++) {
          if (this.totalSelectedComponents[`group_row_component_${this.totalComponents[filterX].ID}`] != null) {
            const targetElement = document.getElementById(`group_row_component_${this.totalComponents[filterX].ID}`);
            const newTargetData = this.handleMultipleDraggedComponents(targetElement);
            const { newTargetElementDateRange } = newTargetData;
            const { draggedElementNewRowID } = newTargetData;
            // @ts-ignore
            const selectedAsset = draggedElementNewRowID.split('<>')[1];
            let startDayAdd = newTargetElementDateRange[0].getDate();
            let endDayAdd = newTargetElementDateRange[0].getDate();
            const offset = (new Date().getTimezoneOffset()) / 60;
            if (offset > parseInt(this.totalComponents[filterX].StartTime.split('T')[1].split(':')[0])) {
              startDayAdd += 1;
            }
            if (offset > parseInt(this.totalComponents[filterX].EndTime.split('T')[1].split(':')[0])) {
              endDayAdd += 1;
            }

            const startDatetimeCheck = new Date(`${newTargetElementDateRange[0].getFullYear()}-${
              pad(newTargetElementDateRange[0].getMonth() + 1, 2, '0')}-${pad(startDayAdd, 2, '0')}T${
                this.totalComponents[filterX].StartTime.split('T')[1]}`);
            const endDatetimeCheck = new Date(`${newTargetElementDateRange[1].getFullYear()}-${
              pad(newTargetElementDateRange[1].getMonth() + 1, 2, '0')}-${pad(endDayAdd, 2, '0')}T${
                this.totalComponents[filterX].EndTime.split('T')[1]}`);
            if (endDatetimeCheck <= startDatetimeCheck) {
              endDayAdd += 1;
            }

            const startDatetimeString = `${newTargetElementDateRange[0].getFullYear()}-${
              pad(newTargetElementDateRange[0].getMonth() + 1, 2, '0')}-${pad(startDayAdd, 2, '0')}T${
                this.totalComponents[filterX].StartTime.split('T')[1]}`;
            const endDatetimeString = `${newTargetElementDateRange[1].getFullYear()}-${
              pad(newTargetElementDateRange[1].getMonth() + 1, 2, '0')}-${pad(endDayAdd, 2, '0')}T${
                this.totalComponents[filterX].EndTime.split('T')[1]}`;

            scheduleModule.updateComponent({
              start_datetime: startDatetimeString,
              end_datetime: endDatetimeString,
              level: this.reverseAssetsDictionary[selectedAsset].level,
              asset: selectedAsset,
              index: filterX,
            });
          }
    }
  }

  // Pulled in from outside
  getComponentDateRangeFromChartLocationWithTemp(component) {
    const targetElementPos = getElementPosition(component, 'DRAGGED');
    const targetElementMinX = targetElementPos.x;
    const targetElementMaxX = targetElementPos.x + component.getBoundingClientRect().width;
    const newStartOfDay = (
      Math.floor((targetElementMinX - SCHEDULE_PIXELS_TO_CURRENT_DAY) / SCHEDULE_CHART_DAY_WIDTH)
      + this.chartStartDatetimeWithOffset().getDate()
    );
    const newEndOfDay = (
      Math.floor((targetElementMaxX - SCHEDULE_PIXELS_TO_CURRENT_DAY) / SCHEDULE_CHART_DAY_WIDTH)
      + this.chartStartDatetimeWithOffset().getDate()
    );
    const firstDayStart = new Date(
      this.chartStartDatetimeWithOffset().getFullYear(), this.chartStartDatetimeWithOffset().getMonth(), 1,
    );
    const firstDayEnd = new Date(
      this.chartStartDatetimeWithOffset().getFullYear(), this.chartStartDatetimeWithOffset().getMonth(), 1,
    );
    const newStartDate = firstDayStart.setDate((firstDayStart.getDate()) + (newStartOfDay + 1));
    const newEndDate = firstDayEnd.setDate((firstDayEnd.getDate()) + (newEndOfDay + 1));
    return [new Date(newStartDate), new Date(newEndDate)];
  }

  snapToClosestDayXValueTemp(targetElement) {
    const targetElementPos = getElementPosition(targetElement, 'DRAGGED');
    targetElementPos.x = (
      (
        getElementPosition(document.getElementById('tempSelectionWrapperElementID'), 'DRAGGED').x
        + targetElementPos.x
      ) - SCHEDULE_PIXELS_TO_CURRENT_DAY
    ) + SCHEDULE_CHART_DAY_WIDTH;
    const targetElementMinX = targetElementPos.x;
    const closestDay = (
      Math.floor(
        (
          (targetElementMinX - (SCHEDULE_CHART_DAY_WIDTH / 1.5)) - SCHEDULE_PIXELS_TO_CURRENT_DAY)
        / SCHEDULE_CHART_DAY_WIDTH,
      ) + this.chartStartDatetimeWithOffset().getDate()) + 0;
    // eslint-disable-next-line no-param-reassign
    targetElement.style.left = `${(closestDay * SCHEDULE_CHART_DAY_WIDTH) - ((
      this.chartStartDatetimeWithOffset().getDate() - 1) * SCHEDULE_CHART_DAY_WIDTH)}px`;
  }

  async snapDraggedElementToRowTemp(targetElement, draggedElementNewRowID) {
    const componentRowID = draggedElementNewRowID;
    // eslint-disable-next-line no-param-reassign
    targetElement.style.top = '10px';
    document.getElementById(componentRowID)!.appendChild(targetElement);
    this.snapToClosestDayXValueTemp(targetElement);
  }

  handleMultipleDraggedComponents(targetElement) {
    const draggedElementNewRowID = this.getClosestRowIDOnDragEnd(targetElement);
    this.snapDraggedElementToRowTemp(targetElement, draggedElementNewRowID);
    const newTargetElementDateRange = this.getComponentDateRangeFromChartLocationWithTemp(targetElement);
    return { newTargetElementDateRange, draggedElementNewRowID };
  }

  snapToClosestDayXValue(targetElement) {
    const targetElementPos = getElementPosition(targetElement, 'DRAGGED');
    const targetElementMinX = targetElementPos.x;
    const closestDay = (
      Math.floor(
        ((targetElementMinX - (SCHEDULE_CHART_DAY_WIDTH / 1.5)) - SCHEDULE_PIXELS_TO_CURRENT_DAY
      ) / SCHEDULE_CHART_DAY_WIDTH,
) + this.chartStartDatetimeWithOffset().getDate()
    ) + 0;
    // eslint-disable-next-line no-param-reassign
    targetElement.style.left = `${(closestDay * SCHEDULE_CHART_DAY_WIDTH) - ((
      this.chartStartDatetimeWithOffset().getDate() - 1) * SCHEDULE_CHART_DAY_WIDTH)}px`;
  }

  snapDraggedElementToRow(targetElement, draggedElementNewRowID) {
    const componentRowID = draggedElementNewRowID;
    // eslint-disable-next-line no-param-reassign
    targetElement.style.top = '10px';
    document.getElementById(componentRowID)!.appendChild(targetElement);
    this.snapToClosestDayXValue(targetElement);
  }

  handleDraggedComponent(e) {
    let targetElement = e.target;
    if (!e.target.hasAttribute('isDraggableComponent')) {
      targetElement = e.target.parentElement;
    }
    if (!targetElement.hasAttribute('isDraggableComponent')) {
      targetElement = targetElement.parentElement;
    }

    const draggedElementNewRowID = this.getClosestRowIDOnDragEnd(targetElement);

    this.snapDraggedElementToRow(targetElement, draggedElementNewRowID);

    const newTargetElementDateRange = this.getComponentDateRangeFromChartLocation(targetElement);

    function pad(n, width, z) {
      const znew = z || '0';
      const nnew = `${n}`;
      return nnew.length >= width ? nnew : new Array(width - nnew.length + 1).join(znew) + nnew;
    }

    // @ts-ignore
    const selectedAsset = draggedElementNewRowID.split('<>')[1];
    for (let x = 0; x < scheduleModule.totalComponents.length; x++) {
      if (scheduleModule.totalComponents[x].ID === targetElement.getAttribute('componentID')) {
        let startDayAdd = newTargetElementDateRange[0].getDate();
        let endDayAdd = newTargetElementDateRange[0].getDate();
        const offset = (new Date().getTimezoneOffset()) / 60;
        if (offset > parseInt(scheduleModule.totalComponents[x].StartTime.split('T')[1].split(':')[0])) {
          startDayAdd += 1;
        }
        if (offset > parseInt(scheduleModule.totalComponents[x].EndTime.split('T')[1].split(':')[0])) {
          endDayAdd += 1;
        }

        const startDatetimeCheck = new Date(`${newTargetElementDateRange[0].getFullYear()}-${
          pad(newTargetElementDateRange[0].getMonth() + 1, 2, '0')}-${pad(startDayAdd, 2, '0')}T${
            scheduleModule.totalComponents[x].StartTime.split('T')[1]}`);
        const endDatetimeCheck = new Date(`${newTargetElementDateRange[1].getFullYear()}-${
          pad(newTargetElementDateRange[1].getMonth() + 1, 2, '0')}-${pad(endDayAdd, 2, '0')}T${
            scheduleModule.totalComponents[x].EndTime.split('T')[1]}`);
        if (endDatetimeCheck <= startDatetimeCheck) {
          endDayAdd += 1;
        }

        const startDatetimeString = `${
          newTargetElementDateRange[0].getFullYear()}-${pad(newTargetElementDateRange[0].getMonth() + 1, 2, '0')}-${
            pad(startDayAdd, 2, '0')}T${scheduleModule.totalComponents[x].StartTime.split('T')[1]}`;
        const endDatetimeString = `${
          newTargetElementDateRange[1].getFullYear()
        }-${pad(newTargetElementDateRange[1].getMonth() + 1, 2, '0')}-${pad(endDayAdd, 2, '0')}T${
          scheduleModule.totalComponents[x].EndTime.split('T')[1]}`;

        scheduleModule.updateComponent({
          start_datetime: startDatetimeString,
          end_datetime: endDatetimeString,
          level: this.reverseAssetsDictionary[selectedAsset].level,
          asset: selectedAsset,
index: x,
        });
        return;
      }
    }
  }

  @Watch('$route.params.schedule_id', {
    immediate: true,
  })
  async onTasqRouteIdChange(id: string) {
    if (this.existingTableID !== id && id !== null && id !== undefined) {
      this.existingTableID = id;
      this.initializePage();
    }
  }

  showAddShiftPopup(row) {
    this.popupBackground = true;
    scheduleModule.setAddShiftRowDetails(row);
    this.showAddShiftPopupVal = true;
  }

  async closeEditShiftTemplatePopup() {
    scheduleModule.setActiveShiftTemplateEdit({ template: null });
    this.shiftTemplates = await scheduleModule.getShiftTemplates();
    this.isHoveringOverTemplateID = '';
  }

  async closeAddShiftTemplatePopup() {
    this.popupBackground = false;
    this.showChangeButtons = true;
    this.addShiftTemplatePopupShowing = false;
    this.shiftTemplates = await scheduleModule.getShiftTemplates();
  }

  closeAddShiftPopup() {
    this.popupBackground = false;
    scheduleModule.setAddShiftRowDetails(null);
    this.showAddShiftPopupVal = false;
    this.showChangeButtons = true;
    this.reshuffleFilterScheduleRowComponents();
  }

  closeEditShiftPopup() {
    this.popupBackground = false;
    scheduleModule.setActiveComponent(null);
    this.showChangeButtons = true;
    this.reshuffleFilterScheduleRowComponents();
  }
}
